var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.visibilityDataList,"fields":_vm.fields,"busy":_vm.isLoadingComponent,"show-empty":"","responsive":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(no)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"cell(is_visible)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":item.is_visible === true ? 'light-success' : 'light-danger'}},[_vm._v(" "+_vm._s(item.is_visible === true ? "Active" : "Not Active")+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"variant":"danger","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" Action ")]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"EditIcon"}}),_vm._v(" Update ")],1)],1)]}}])}),_c('b-modal',{attrs:{"id":"form-visibility","centered":"","title":"Edit Feature","size":"lg","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){return [_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isLoading},on:{"click":function($event){return _vm.updateItem()}}},[_vm._v(" Edit Item ")])]}}])},[_c('ValidationObserver',[_c('div',{staticClass:"form"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Name:")]),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPayloadVisibility.name),expression:"formPayloadVisibility.name"}],staticClass:"form-control",class:classes,attrs:{"id":"name","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Name"},domProps:{"value":(_vm.formPayloadVisibility.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formPayloadVisibility, "name", $event.target.value)}}}),_vm._l((_vm.validationsForm.name),function(validation,index){return _c('small',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"order"}},[_vm._v("Sort Number:")]),_c('validation-provider',{attrs:{"name":"order","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-form-input',{class:classes,attrs:{"type":"text","id":"order","placeholder":"Order","state":errors.length > 0 ? false : null},model:{value:(_vm.formPayloadVisibility.order),callback:function ($$v) {_vm.$set(_vm.formPayloadVisibility, "order", $$v)},expression:"formPayloadVisibility.order"}}),_vm._l((_vm.validationsForm.order),function(validation,index){return _c('small',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"type"}},[_vm._v("Status:")]),_c('div',{staticClass:"demo-inline-spacing",staticStyle:{"margin-top":"8px"}},[_c('div',{staticClass:"custom-control custom-radio mt-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPayloadVisibility.is_visible),expression:"formPayloadVisibility.is_visible"}],staticClass:"custom-control-input",attrs:{"name":"inlineRadioOptions","type":"radio","id":"radio1","value":"true"},domProps:{"checked":_vm._q(_vm.formPayloadVisibility.is_visible,"true")},on:{"change":function($event){return _vm.$set(_vm.formPayloadVisibility, "is_visible", "true")}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"radio1"}},[_vm._v(" Aktif ")])]),_c('div',{staticClass:"custom-control custom-radio mt-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPayloadVisibility.is_visible),expression:"formPayloadVisibility.is_visible"}],staticClass:"custom-control-input",attrs:{"name":"inlineRadioOptions","type":"radio","id":"radio2","value":"false"},domProps:{"checked":_vm._q(_vm.formPayloadVisibility.is_visible,"false")},on:{"change":function($event){return _vm.$set(_vm.formPayloadVisibility, "is_visible", "false")}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"radio2"}},[_vm._v(" Tidak Aktif ")])])])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }