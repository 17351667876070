<template>
  <b-card title="Feature List">
    <Table
      :visibilityData="visibilityData"
      :is-loading="isLoading"
      :get-data="getData"
      :formPayload="formPayload"
      :edit-item="editItem"
      :update-item="updateItem"
      :changeOrder="changeOrder"
      :validations="validations"
    />
  </b-card>
</template>

<script>
import Table from "@/components/feature/Table.vue";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      visibilityData: {},
      isLoadingExport: false,
      formPayload: {
        name: "",
        order: "",
        is_visible: "",
      },
      validations: "",
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    cleanUpForm() {
      this.formPayload = {};
      this.validations = "";
    },
    editItem(item) {
      console.log(item, "data item");
      this.cleanUpForm();
      this.editId = item.id;
      this.formPayload = {
        name: item.name,
        order: item.order,
        is_visible: item.is_visible.toString(),
      };
      this.$bvModal.show("form-visibility");
    },

    updateItem() {
      this.isLoading = true;
      this.$http
        .post(
          `/api/v1/admin/feature-visibility/${this.editId}`,
          this.formPayload
        )
        .then((response) => {
          this.cleanUpForm();
          this.$bvModal.hide("form-visibility");
          this.getData();
          successNotification(
            this,
            "Success",
            "Visibility successfully updated!"
          );
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },

    changeOrder(evt) {
      let idList = evt.moved.element.id;
      this.$http
        .post(`/api/v1/admin/feature-visibility/${idList}`, {
          name: evt.moved.element.name,
          order: evt.moved.newIndex + 1,
          is_visible: evt.moved.element.is_visible.toString(),
        })
        .then((response) => {
          successNotification(
            this,
            "Success",
            "Visibility successfully updated!"
          );
          this.getData();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },
    getData() {
      this.isLoading = true;
      this.$http
        .get("/api/v1/admin/feature-visibility")
        .then((response) => {
          this.visibilityData = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss"></style>
