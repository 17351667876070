<template>
  <div class="row">
    <b-table
      striped
      hover
      :items="visibilityDataList"
      :fields="fields"
      :busy="isLoadingComponent"
      show-empty
      responsive
    >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(no)="{ index }">
        {{ index + 1 }}
      </template>

      <template v-slot:cell(is_visible)="{ item }">
        <b-badge
          :variant="
            item.is_visible === true ? 'light-success' : 'light-danger'
          "
        >
          {{ item.is_visible === true ? "Active" : "Not Active" }}
        </b-badge>
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-dropdown variant="danger" size="sm">
          <template #button-content> Action </template>

          <b-dropdown-item @click="editItem(item)">
            <feather-icon icon="EditIcon" class="mr-75" />
            Update
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-modal
      id="form-visibility"
      centered
      title="Edit Feature"
      size="lg"
      no-close-on-backdrop
    >
      <ValidationObserver>
        <div class="form">
          <div class="form-group">
            <label for="name">Name:</label>
            <validation-provider
              name="name"
              rules="required"
              v-slot="{ errors, classes }"
            >
              <input
                id="name"
                type="text"
                class="form-control"
                :class="classes"
                :state="errors.length > 0 ? false : null"
                v-model="formPayloadVisibility.name"
                placeholder="Name"
              />
              <small
                v-for="(validation, index) in validationsForm.name"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </validation-provider>
          </div>
          <div class="form-group">
            <label for="order">Sort Number:</label>
            <validation-provider
              name="order"
              rules="required"
              v-slot="{ errors, classes }"
            >
              <b-form-input
                type="text"
                id="order"
                v-model="formPayloadVisibility.order"
                placeholder="Order"
                :class="classes"
                :state="errors.length > 0 ? false : null"
              ></b-form-input>
              <small
                v-for="(validation, index) in validationsForm.order"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </validation-provider>
          </div>
          <div class="form-group">
            <label for="type">Status:</label>
            <div class="demo-inline-spacing" style="margin-top: 8px">
              <div class="custom-control custom-radio mt-0">
                <input
                  name="inlineRadioOptions"
                  class="custom-control-input"
                  type="radio"
                  id="radio1"
                  value="true"
                  v-model="formPayloadVisibility.is_visible"
                />
                <label class="custom-control-label" for="radio1"> Aktif </label>
              </div>
              <div class="custom-control custom-radio mt-0">
                <input
                  name="inlineRadioOptions"
                  class="custom-control-input"
                  type="radio"
                  id="radio2"
                  value="false"
                  v-model="formPayloadVisibility.is_visible"
                />
                <label class="custom-control-label" for="radio2">
                  Tidak Aktif
                </label>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
      <template #modal-footer="{}">
        <b-button
          variant="primary"
          @click="updateItem()"
          v-bind:disabled="isLoading"
        >
          Edit Item
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BTable,
  BSpinner,
  BBadge,
  BImg,
  BDropdown,
  BPagination,
  BDropdownItem,
  BButton,
  BFormInput,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BTable,
    BSpinner,
    BBadge,
    BImg,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormInput,
    draggable,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    visibilityData: {
      type: "",
    },
    formPayload: {
      type: "",
    },
    validations: {
      type: "",
    },
    editId: {
      type: "",
    },

    isLoading: {
      type: Boolean,
    },
    getData: {
      type: Function,
    },
    deleteItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
    changeOrder: {
      type: Function,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value;
    },
    visibilityData(value) {
      this.visibilityDataList = value;
    },
    formPayload(value) {
      this.formPayloadVisibility = value;
    },
    validations(value) {
      this.validationsForm = value;
    },
    // filter: {
    //   handler(value) {
    //     this.$emit("filter", value);
    //   },
    //   deep: true,
    // },
  },
  data() {
    return {
      required,
      isLoadingComponent: true,
      visibilityDataList: [],
      editIdModal: null,
      formPayloadVisibility: {
        name: "",
        is_visible: "",
      },
      fields: [
        { key: "no" },
        { key: "name" },
        { key: "location" },
        { key: "order", label: "Sort Number" },
        { key: "is_visible", label: "Status" },
        { key: "actions" },
      ],
      validationsForm: "",
    };
  },
  computed: {
    rows() {
      return this.visibilityDataList.length;
    },
  },
};
</script>

<style lang="scss"></style>
